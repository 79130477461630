import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import Img from 'gatsby-image';
import './AwardsBlock.scss';

export const AwardsBlock = ({ data }) => (
  <div className="aaa-awards-block">
    <ContentBox>
      <div className="aaa-awards-block__container">
        <div className="row justify-content-center">
          {data.icons.map((item, i) => (
            <div key={i} className="col-6 col-md-4 col-lg d-flex flex-column">
              <div className="aaa-awards-block__icon-container">
                <Img
                  className="aaa-awards-block__icon-image"
                  fluid={item.image.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'contain',
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </ContentBox>
  </div>
);

export default AwardsBlock;
