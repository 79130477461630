import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import CTAForm from 'components/CTAForm';
import InfoBlock, { IconsBlock } from 'components/InfoBlock';
import AwardsBlock from 'components/AwardsBlock/AwardsBlock';
import InfoCardBig from 'components/InfoCardBig/InfoCardBig';
import TestimonialsContainer from 'components/TestimonialsContainer/TestimonialsContainer';
import ScrollToTopButton from 'components/ScrollToTopButton';

const WhyAAAPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO
        meta={data.whyAaaYaml.metaTags}
        title={data.whyAaaYaml.title}
        titleTemplate="%s"
      />
      <Hero data={data.whyAaaYaml.hero} />
      <IconsBlock data={data.whyAaaYaml.iconsBlock} nobg={true} />
      <InfoBlock data={data.whyAaaYaml.aaaPart} />
      {data.whyAaaYaml.bigInfoCards.map((card, i) => (
        <InfoCardBig key={i} data={card} />
      ))}
      <InfoBlock data={data.whyAaaYaml.infoBlock} nobg />
      <AwardsBlock data={data.whyAaaYaml.awardsBlock} />
      <TestimonialsContainer />
      <CTAForm />
      <ZipGateContainer location={location} />
      <ScrollToTopButton />
    </Layout>
  );
};

export default WhyAAAPage;

export const pageQuery = graphql`
  query WhyAAAPageQuery {
    whyAaaYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        size
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroImagePosition
        primaryButton {
          text
          url
        }
      }

      iconsBlock {
        icons {
          text
          icon
        }
      }

      aaaPart {
        title
        subtitle
        infoCards {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 405) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          link {
            text
            url
          }
        }
      }

      bigInfoCards {
        color
        type
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 891, maxHeight: 521) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageLink
      }

      infoBlock {
        title
        subtitle
        infoCards {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 359) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          note
          link {
            text
            url
          }
        }
      }

      awardsBlock {
        icons {
          image {
            childImageSharp {
              fluid(maxHeight: 89) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`;
